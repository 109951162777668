import React, {useEffect} from 'react';
import {Provider as ReduxProvider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import i18next from 'i18next';
import {I18nextProvider} from 'react-i18next';

import './styles/custom.scss';

import './locales/i18n';

import {store, persistor} from 'state';
import Routes from 'routes';

export const App: React.FC = () => {
  useEffect(() => {
    const currentLocale = localStorage.getItem('locale') || 'en';
    localStorage.setItem('locale', currentLocale);
    i18next.changeLanguage(currentLocale);
  }, []);

  return (
    <ReduxProvider store={store}>
      <I18nextProvider i18n={i18next}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes />
        </PersistGate>
      </I18nextProvider>
    </ReduxProvider>
  );
};

export default App;
