import React, {useEffect, useState, useCallback} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import axios from '../axiosConfig';
import {useAppSelector, useAppDispatch} from 'state/hooks';
import Spinner from 'components/Spinner';
import {
  initiatePayment,
  resetPayment,
  setPaymentFailure,
  setPaymentSuccess,
} from 'state/payment';

const PAYMENT_CHECK_INTERVAL = 3000; // Check every 3 seconds
const MAX_PAYMENT_CHECKS = 20; // Maximum number of checks (60 seconds total)

const PaymentResult = () => {
  const dispatch = useAppDispatch();
  const {user, token} = useAppSelector((state) => state.login);
  const {orderId: storedOrderId} = useAppSelector((state) => state.payment);
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const nav = useNavigate();
  const queryString = useLocation().search;
  const urlParams = new URLSearchParams(queryString);
  const orderId = urlParams.get('orderId') || storedOrderId;

  const [isLoading, setIsLoading] = useState(true);
  const [checkCount, setCheckCount] = useState(0);

  const handleReturnToHomepage = useCallback(() => {
    nav('/');
  }, [nav]);

  const handlePaymentFailure = useCallback(() => {
    dispatch(setPaymentFailure('Payment failed'));
    handleReturnToHomepage();
  }, [dispatch, handleReturnToHomepage]);

  const handleStartVideoCall = useCallback(
    (paymentId: string) => {
      nav('/video-call', {
        state: {paymentId, user, logInToken: token},
      });
    },
    [nav, user, token],
  );

  const createPayment = useCallback(
    async (orderId: string, response_data: any) => {
      try {
        const headers = {Authorization: `Token ${token}`};
        const requestData = {orderId, response_data};
        const response = await axios.post(
          `${baseURL}/create-payment/`,
          requestData,
          {headers},
        );
        return response.data;
      } catch (error) {
        console.error('Error creating payment:', error);
        return null;
      }
    },
    [baseURL, token],
  );

  const checkOrderStatus = useCallback(async () => {
    if (!orderId) {
      handlePaymentFailure();
      return;
    }

    try {
      const headers = {Authorization: `Token ${token}`};
      const response = await axios.get(
        `${baseURL}/get-order-payment/${orderId}/`,
        {headers},
      );

      if (response.status === 201) {
        const paymentData = response.data?.payment_data;
        const transactions = paymentData?.result?.transactions;
        const orderStatus = transactions?.[0]?.status;
        const userNationality = user?.nationality;
        let vat = 0;
        let amountWithVat = transactions[0]?.amount;

        if (userNationality && userNationality.toUpperCase() !== 'SA') {
          vat = amountWithVat / 1.15;
        }
        if (orderStatus === 'SUCCESS') {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'paymentSuccess',
            result: {
              value: transactions[0]?.amount,
              currency: transactions[0]?.currency,
              order_id: orderId,
              vat: vat,
            },
          });
          const createPaymentResponse = await createPayment(
            orderId,
            paymentData,
          );

          if (createPaymentResponse?.id) {
            dispatch(setPaymentSuccess(orderId));
            handleStartVideoCall(createPaymentResponse.id);
            return true; // Payment successful
          }
        } else if (orderStatus === 'FAILED' || orderStatus === 'CANCELLED') {
          handlePaymentFailure();
          return true; // Payment failed/cancelled
        }
      }
      return false; // Payment still pending
    } catch (error) {
      console.error('Error checking payment status:', error);
      if (checkCount >= MAX_PAYMENT_CHECKS) {
        handlePaymentFailure();
        return true;
      }
      return false;
    }
  }, [
    baseURL,
    orderId,
    token,
    checkCount,
    createPayment,
    dispatch,
    handlePaymentFailure,
    handleStartVideoCall,
  ]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    const pollPaymentStatus = async () => {
      if (checkCount >= MAX_PAYMENT_CHECKS) {
        handlePaymentFailure();
        setIsLoading(false);
        return;
      }

      const isComplete = await checkOrderStatus();

      if (isComplete) {
        setIsLoading(false);
        if (intervalId) {
          clearInterval(intervalId);
        }
      } else {
        setCheckCount((prev) => prev + 1);
      }
    };

    // Initial check
    pollPaymentStatus();

    // Set up polling interval
    intervalId = setInterval(pollPaymentStatus, PAYMENT_CHECK_INTERVAL);

    // Cleanup
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [checkCount, checkOrderStatus, handlePaymentFailure]);

  // Show error message if taking too long
  const showTimeoutMessage = checkCount >= MAX_PAYMENT_CHECKS / 2;

  return (
    <div className="payment-result-container">
      {isLoading && (
        <div className="payment-status">
          <Spinner />
          {showTimeoutMessage && (
            <p className="payment-timeout-message">
              Payment verification is taking longer than expected. Please
              wait...
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default PaymentResult;
