import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import axios from '../axiosConfig';

export const initiatePayment = createAsyncThunk(
  'payment/initiate',
  async (orderId: string, {rejectWithValue}) => {
    try {
      const response = await axios.post('/payment/initiate', {orderId});
      return response.data;
    } catch (error) {
      return rejectWithValue('Payment failed');
    }
  },
);

export const setPaymentSuccess = createAsyncThunk(
  'payment/setSuccess',
  async (orderId: string) => ({orderId}),
);

export const setPaymentFailure = createAsyncThunk(
  'payment/setFailure',
  async (error: string) => ({error}),
);

const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    orderId: null as string | null,
    paymentStatus: 'idle' as 'idle' | 'loading' | 'success' | 'failed',
    error: null as string | null,
  },
  reducers: {
    resetPayment: (state) => {
      state.paymentStatus = 'idle';
      state.error = null;
      state.orderId = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initiatePayment.pending, (state) => {
        state.paymentStatus = 'loading';
      })
      .addCase(initiatePayment.fulfilled, (state, action) => {
        state.paymentStatus = 'success';
        state.orderId = action.payload.orderId;
      })
      .addCase(initiatePayment.rejected, (state, action) => {
        state.paymentStatus = 'failed';
        state.error = action.payload as string;
      })
      .addCase(setPaymentSuccess.fulfilled, (state, action) => {
        state.paymentStatus = 'success';
        state.orderId = action.payload.orderId;
      })
      .addCase(setPaymentFailure.fulfilled, (state, action) => {
        state.paymentStatus = 'failed';
        state.error = action.payload.error;
      });
  },
});

export const {resetPayment} = paymentSlice.actions;
export default paymentSlice.reducer;
